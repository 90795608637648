
          @import "src/styles/main.scss";
        
.aside {
  width: 200px;
  background-color: $color-black;
  color: $color-white;
  min-height: 100vh;
  text-align: left;

  header {
    padding: 25px;
    width: 100%;
    min-width: 200px;

    h2,
    h3 {
      margin: 0px;
    }
    h2 {
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 0.32px;
      cursor: pointer;
    }
    h3 {
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 0.224px;
    }
  }
  menu {
    width: 100%;
    padding-left: 0px;

    .linkItem {
      text-decoration: none;
      color: $color-white;
      font-weight: 700;
      text-align: left;
    }
    .active {
      padding-left: 50px;
      transition: all ease 0.2s;
      background-color: #646464;
      border-left: 3px solid $color-white;
    }

    li {
      width: 100%;
      list-style: none;
      padding: 20px 0px 20px 48px;
      position: relative;
      &:hover {
        padding-left: 50px;
        transition: all ease 0.2s;
        background-color: #646464;
        border-left: 3px solid $color-white;
      }
    }
  }
}

@media (max-width: 420px) {
  .aside {
    display: none;
  }
}
