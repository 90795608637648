
          @import "src/styles/main.scss";
        
.link {
  color: $color-blue;
  text-align: right;
  width: 100%;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0.5px;
}
