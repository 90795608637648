
          @import "src/styles/main.scss";
        
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader {
  border: 3px solid $color-white;
  border-top: 3px solid $color-grey;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;

  &.sm {
    width: 20px;
    height: 20px;
  }

  &.md {
    width: 35px;
    height: 35px;
  }

  &.lg {
    margin: 50px;
    width: 50px;
    height: 50px;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
