
          @import "src/styles/main.scss";
        
.table-container {
  background: $color-white;
  border-radius: 12px;
  overflow: auto;
  width: 100%;
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 4px;
    height: 4px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: grey;
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
  .title-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem;
    .tableTitle {
      margin: 10px 0px;
    }

    .filter-button {
      display: flex;
      gap: 10px;
      align-items: center;
      cursor: pointer;
      max-width: 100px;
      width: 100%;
    }
  }
  .tableWrap {
    max-height: 60vh; /* or whatever height you prefer */
    overflow-y: scroll;
  }

  table {
    width: 100%;
    // table-layout: fixed; /* Ensures consistent column sizing */
  }
  thead{
    position: sticky;
    top:0
  }

  thead,
  tfoot,
  tbody tr {
    // display: table;
    // width: 100%;
  }

  thead,
  tfoot {
    width: calc(100%); /* Adjust if there is a scrollbar */
  }

  .paginationButton {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .loadMoreButton {
      background: transparent;
      margin: 8px;
      border: none;
      color: $color-blue;
      font-weight: bold;
      text-align: end;
      text-decoration: underline;
      cursor: pointer;
      &:disabled {
        color: $color-grey;
      }
    }
  }
}

table {
  border-spacing: 0px;
  width: 100%;
  font-size: 16px;
  white-space: nowrap;
  thead {
    background-color: $color-black;
    color: $color-white;
    tr {
      th {
        padding: 8px 15px;
        min-width: 100px;
        text-align: left;
      }
      th:nth-child(1) {
        min-width: 40px;
      }
    }
  }
  tbody {
    tr {
      td {
        padding: 8px 15px;
        border-bottom: 1px solid lightgrey;
        max-width: 260px;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: left;
      }
      // td:nth-child(4) {
      //   text-align: center;
      // }
      td:last-child {
        text-align: center;
      }
    }
  }
}
