
          @import "src/styles/main.scss";
        
// Stepper.scss
.stepperWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $color-white;
  border-radius: 16px;
  padding: 10px 30px;
  max-width: 500px;
  width: 100%;
  gap: 20px;

  .stepper {
    position: relative;
    // width: 100px;
    // height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;

    .circleBack {
      fill: none;
      stroke: #ddd;
      stroke-width: 5;
    }

    .circleFront {
      fill: none;
      stroke: $color-purple;
      stroke-width: 5;
      transform: rotate(-90deg);
      transform-origin: 50% 50%;
      transition: stroke-dashoffset 0.3s ease;
    }

    .stepNumber {
      position: absolute;
      width: 100%;
      text-align: center;
      font-size: 0.8rem;
      color: black;
    }
  }
}
