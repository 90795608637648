
          @import "src/styles/main.scss";
        
.accountDetails {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  // max-height: 90vh;
  // overflow: auto;
  .accountDeactivateWrapper {
    .accountDeactivate {
      max-width: 23rem;
      width: 100%;
    }
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 3rem;
  }
  .profileImage {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    img{
      background-color: $color-grey;
    }
  }
  .card {
    background-color: $color-white;
    border-radius: 16px;
    max-width: fit-content;
    width: 100%;
    min-height: 600px;
    padding: 65px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    overflow: auto;
    flex-direction: column;
    gap: 24px;
    margin-bottom: 3rem;
  }
  .item {
    width: 100%;
    gap: 1.5rem;
    display: flex;
    align-items: start;
    justify-content: start;
    .itemLabel {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 150% */
      text-transform: uppercase;
      width: 35%;
      text-align: left;
    }

    .itemValue {
      color: #333;
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      width: 65%;
      text-align: left;
    }
  }
  .bikesList {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.5rem;
    .bikeDetail {
      display: flex;
      gap: 1.5rem;
      .bikeImage {
        background-color: $color-grey;
      }

      .details {
        display: flex;
        flex-direction: column;
        text-align: left;
        justify-content: center;
        gap: 0.5rem;
        span {
          font-size: 1.25rem;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }
      }
    }
  }
}
