
          @import "src/styles/main.scss";
        
// Modal.module.scss
.modalBackdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(17, 17, 17, 0.8);
  z-index: 1000;

  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-thumb {
    background: $color-grey;
    border-radius: 5px;
  }

  ::-webkit-scrollbar-track {
    background: $color-grey-light;
    border-radius: 5px;
    margin: 2px;
  }
}

.modalContent {
  background: white;
  // padding: 10px;
  border-radius: 6px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  max-height: 88vh;
  min-width: 450px;
  max-width: 450px;
  overflow: auto;
}

@media (max-width: 420px) {
  .modalContent {
    min-width: 300px;
  }
}
