
          @import "src/styles/main.scss";
        
.app-shell {
  display: flex;
  flex-direction: row;
  min-height: 100vh;
  height: 100%;
  width: 100%;
}

.content {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  width: 80%;
}

main {
  flex-grow: 1;
  background-color: #f0f0f0;
}
