
          @import "src/styles/main.scss";
        
.notification {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: $color-white;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  border-left: 5px solid $color-red;
  margin-bottom: 10px;
  position: fixed;
  right: 20px;
  top: 30px;
  z-index: 10000;

  &.success {
    border-left: 5px solid $color-green;
  }

  p {
    margin: 0;
    flex-grow: 1;
  }

  .close-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    color: #555;
    font-size: 18px;
    margin-left: 10px;

    &:hover {
      color: $color-red;
    }
  }
}
