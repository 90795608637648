
          @import "src/styles/main.scss";
        
$text-colors: (
  black: $color-black,
  blue: $color-blue,
  blue-light: $color-blue-light,
  white: $color-white,
  purple: $color-purple,
  red: $color-red,
  yellow: $color-yellow,
  green: $color-green,
  grey: $color-grey,
);

$text-align: (
  left: left,
  right: right,
  center: center,
  justify: justify,
);

$text-weights: (
  light: 300,
  normal: 400,
  medium: 500,
  bold: 700,
);

$font-families: (
  lato: $lato,
);

$font-sizes: (
  xxs: 12px,
  xs: 14px,
  sm: 16px,
  md: 18px,
  lg: 24px,
  xl: 32px,

  h1: 100px,
  h2: 80px,
  h3: 64px,
  h4: 48px,
  h5: 32px,
  h6: 24px,
);

.text {
  margin: 0;
  padding: 0;
  white-space: pre-wrap;
  width: 100%;

  @each $font-size, $value in $font-sizes {
    &.#{$font-size} {
      font-size: $value;
    }
  }

  @each $color, $value in $text-colors {
    &.#{$color} {
      color: $value;
    }
  }

  @each $weight, $value in $text-weights {
    &.#{$weight} {
      font-weight: $value;
    }
  }

  @each $align, $value in $text-align {
    &.#{$align} {
      text-align: $value;
    }
  }

  @each $font-family, $value in $font-families {
    &.#{$font-family} {
      font-family: $value;
    }
  }
}
