
          @import "src/styles/main.scss";
        
$title-colors: (
  black: $color-black,
  blue: $color-blue,
  blue-light: $color-blue-light,
  white: $color-white,
  purple: $color-purple,
);

$title-align: (
  left: left,
  right: right,
  center: center,
);

$font-families: (
  lato: $lato,
);

$title-weights: (
  normal: 400,
  bold: 700,
);

$title-sizes: (
  h1: 100px,
  h2: 80px,
  h3: 64px,
  h4: 48px,
  h5: 32px,
  h6: 24px,
);
$breakpoint-mobile: 400px;

$title-sizes-mobile: (
  h1: 20px,
  h2: 32px,
  h3: 16px,
  h4: 16px,
  h5: 24px,
  h6: 20px,
);

.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin: 0px;
  padding: 0px;
  line-height: normal;
}

.title {
  @each $color, $value in $title-colors {
    &.#{$color} {
      color: $value;
    }
  }

  @each $size, $value in $title-sizes {
    &.#{$size} {
      font-size: $value;
    }
  }

  @each $weight, $value in $title-weights {
    &.#{$weight} {
      font-weight: $value;
    }
  }

  @each $align, $value in $title-align {
    &.#{$align} {
      text-align: $value;
    }
  }

  @each $font-family, $value in $font-families {
    &.#{$font-family} {
      font-family: $value;
    }
  }
}

@media (max-width: $breakpoint-mobile) {
  .title {
    @each $size, $value in $title-sizes-mobile {
      &.#{$size} {
        font-size: $value;
      }
    }
  }
}
