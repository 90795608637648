
          @import "src/styles/main.scss";
        
.breadcrumb {
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 1rem;
  display: flex;
  cursor: pointer;
}

.breadcrumb-item {
  display: inline-block;
}

a {
  text-decoration: none;
  color: #201f1e;
  transition: color 0.2s;
  padding: 0 8px;

  &:hover {
    color: black;
  }
}

.current {
  color: black;
  padding: 0 8px;
}

.separator {
  svg {
    height: 12px;
    width: 12px;
  }
}
