$color-black: #111111;
$color-white: #ffffff;
$color-yellow: #ffa500;
$color-purple: #722e69;
$color-blue: #0000ff;
$color-blue-light: #585ef2;
$color-red: #b20a0a;
$color-grey: #969696;
$color-grey-light: #dadada;
$color-green: #008000;
