
          @import "src/styles/main.scss";
        
.nav {
  background-color: $color-white;
  color: $color-black;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 48px;
  padding: 8px 48px;
  h3 {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    cursor: pointer;
  }
  .menu {
    display: flex;
    gap: 10px;
    align-items: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 12px;
    cursor: pointer;
  }
  .mobileMenu {
    display: none;
  }

  .dropdownMenu {
    position: absolute;
    top: 40px;
    right: 35px;
    background-color: $color-white;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    z-index: 1;
    list-style: none;
    padding: 0;
    margin: 0;
    border: 1px solid $color-grey-light;
    border-radius: 4px;
    display: none;

    li {
      padding: 8px 16px;
      border-bottom: 1px solid $color-grey-light;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      overflow: hidden;
      color: $color-black;
      text-decoration: none;
      display: block;
      cursor: pointer;

      &:last-child {
        border-bottom: none;
      }

      &:hover {
        background-color: $color-grey-light;
      }
    }
  }
  &:hover {
    .dropdownMenu {
      display: block;
    }
  }
}

@media (max-width: 420px) {
  .nav {
    background-color: $color-purple;
    color: $color-white;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 48px;
    padding: 8px 15px;
    h3 {
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 0.256px;
      cursor: pointer;
    }
    .menu {
      display: none;
    }
    .mobileMenu {
      display: flex;
      gap: 30px;
      align-items: center;
      font-size: 10px;
      font-style: normal;
      font-weight: 500;
      line-height: 12px;
      div {
        display: flex;
        gap: 10px;
        align-items: center;
      }
      cursor: pointer;

      &:hover {
        .dropdownMenu {
          display: block;
        }
      }
    }
  }
}
