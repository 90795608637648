
          @import "src/styles/main.scss";
        
.button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  border: none;
  padding: 8px 32px;
  color: $color-white;
  width: 100%;
  font-family: $lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: 0.25px;
  border-radius: 6px;
  margin-top: 20px;
  cursor: pointer;
  &.yellow {
    background-color: $color-yellow;
  }
  &.black {
    background-color: $color-black;
    color: $color-white;
  }
  &.purple {
    margin-top: -15px;

    background-color: $color-purple;
  }
  &.grey {
    background-color: $color-grey;
  }
  &.action {
    margin-top: 0px;
    background-color: #00a0d2;
    width: 100%;
    align-items: center;
    padding: 0px 36px;
    line-height: normal;
    gap: 0.75rem;
  }
  &.deactivate {
    margin-top: 0px;
    background-color: #646464;
    width: 100%;
    align-items: center;
    padding: 0px 36px;
    line-height: normal;
    gap: 0.75rem;
  }
}
