//font-sizes
$font-xs: 12px;
$font-sm: 14px;
$font-base: 16px;
$font-md: 18px;
$font-lg: 24px;
$font-xl: 32px;

// heading-sizes
$heading-1: 100px;
$heading-2: 80px;
$heading-3: 64px;
$heading-4: 48px;
$heading-5: 32px;
$heading-6: 24px;

//font weights
$font-300: 300;
$font-400: 400;
$font-500: 500;
$font-700: 700;

// font family
$lato: var(--font-lato);


// line heights
$line-height-small: 75%;
$line-height-normal: 100%;
$line-height-medium: 150%;
$line-height-large: 160%;
