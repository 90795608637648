
          @import "src/styles/main.scss";
        
.inputWrapper {
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
  gap: 6px;
  .label {
    color: $color-black;
    font-family: $lato;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.5px;
  }
  .input {
    width: 100%;
    height: 40px;
    padding: 8px 16px;
    border-radius: 6px;
    border: 1px solid $color-black;
    &::placeholder {
      font-size: $font-base;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 120%;
      letter-spacing: 0.25px;
      color: $color-grey;
    }
  }
}
