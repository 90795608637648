
          @import "src/styles/main.scss";
        
@font-face {
  font-family: "Lato", sans-serif;
  src: url("../common/assets/fonts/Lato/Lato-Regular.ttf");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Lato", sans-serif;
  src: url("../common/assets/fonts/Lato/Lato-Regular.ttf");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Lato", sans-serif;
  src: url("../common/assets/fonts/Lato/Lato-Bold.ttf");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Noto Sans JP", sans-serif;
  src: url("../common/assets/fonts/Lato/Noto-sans-400.ttf");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Noto Sans JP", sans-serif;
  src: url("../common/assets/fonts/Lato/Noto-sans-500.ttf");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Noto Sans JP", sans-serif;
  src: url("../common/assets/fonts/Lato/Noto-sans-700.ttf");
  font-weight: 700;
  font-style: normal;
}

:root {
  --font-noto: "Noto Sans JP", sans-serif;
}

html,
body {
  margin: 0;
  font-family: var(--font-noto);
}

* {
  box-sizing: border-box;
}
