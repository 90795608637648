
          @import "src/styles/main.scss";
        
.adminDashboard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 10px 30px;

  .headerButtonContainer {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    width: 100%;
    gap: 10px;
  }
}

// Dashboard.module.scss

.dashboardContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  height: 100vh;
  overflow: auto;
  width: 100%;
  padding: 1.56rem;
}

.topControls {
  padding: 1.56rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.controlButton {
  max-width: 17.25rem;
  width: 100%;
}

.graphContainer {
  width: 100%;
  height: 100%;
  max-width: 65rem;
  background-color: white;
  padding: 2rem;
  border-radius: 1rem;
  margin-top: 3rem;
}

.chartContainer {
  width: 100%;
  height: 100%;
  max-width: 65rem;
  background-color: white;
  padding: 2rem;
  border-radius: 1rem;
  margin-top: 3rem;
}
.graphArea {
  width: 100%;
  height: 100%;
  max-width: 65rem;
  max-height: 25rem;
  height: 100%;
}

.chartArea {
  width: 100%;
  max-width: 65rem;
  min-height: 18rem;
  max-height: 18rem;
  height: 100%;
}

.dateControl {
  display: flex;
  justify-content: space-between;
  padding-inline: 2rem;
  span {
    font-family: var(--font-noto);
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
}

.control {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  font-family: var(--font-noto);
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  cursor: pointer;
}
